<template>
  <CModal
      color="primary"
      title="Створення FAQ"
      :show.sync="showEditor"
      :close-on-backdrop="false"
      size="xl"
      addContentClasses="srl"
  >
    <template #body-wrapper>
      <CCol>
        <div class="form-item mt-3" :class="{'errorInput': $v.title.$error}">
          <CInput
              placeholder="Заповніть заголовок"
              v-model="title"
              @change="$v.title.$touch()"
              :class="{
                'error': $v.title.$error
              }"
          />
          <small v-if="!$v.title.required" class="form-text errorText w-100">Необхідно заповнити
            <strong>заголовок</strong>.</small>
          <small v-if="!$v.title.maxLength" class="form-text errorText w-100">Максимальна
            кількість символів <strong>{{ $v.title.$params.maxLength.max }}</strong>!</small>
        </div>
        <CRow>
          <span class="mt-1 mx-2 ml-4">Тільки для працівників банку: </span>
          <CSwitch
              label-off="Ні"
              label-on="Так"
              color="primary"
              variant="opposite"
              :checked.sync="for_bank_employees"
          />
        </CRow>
        <div class="form-item mt-3" :class="{'errorInput': $v.content.$error}">
          <editor
              v-model="content"
              apiKey="gcwfxbve56yn4yb320pr6yorz3i8rhxlomcungqzverhey18"
              :init="editorConfig"
              @change="$v.content.$touch()"
              :class="{
                'error': $v.content.$error
              }"
          />
          <small v-if="!$v.content.required" class="form-text errorText w-100">Необхідно заповнити
            <strong>довідник</strong>.</small>
        </div>
      </CCol>
    </template>
    <template #footer>
      <CRow class="w-100">
        <CCol class="text-left">
          <CButton color="secondary" @click="cancelEditor">
            Скасувати
          </CButton>
        </CCol>
        <CCol class="text-right">
          <CButton
              @click="validationForm"
              color="primary">
            {{ !editId ? 'Зберегти' : 'Оновити' }}
          </CButton>
        </CCol>
      </CRow>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios'
import {required, minLength, maxLength} from 'vuelidate/lib/validators'

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

import Editor from "@tinymce/tinymce-vue";

export default {
  name: "ModalTextEditor",
  props: {
    showEditor: {
      type: Boolean,
      required: true,
      default: false
    },
    editId: {
      type: [Number, String],
      required: false,
      default: null
    },
  },

  components: {'editor': Editor},

  data: () => {
    return {
      title: "",
      content: "",
      for_bank_employees: false,
      showID: 0,
      editorConfig: {
        paste_data_images: true,
        external_plugins: {
          advlist: '/tinymce/plugins/advlist/plugin.min.js',
          anchor: '/tinymce/plugins/anchor/plugin.min.js',
          autolink: '/tinymce/plugins/autolink/plugin.min.js',
          charmap: '/tinymce/plugins/charmap/plugin.min.js',
          code: '/tinymce/plugins/code/plugin.min.js',
          codesample: '/tinymce/plugins/codesample/plugin.min.js',
          contextmenu: '/tinymce/plugins/contextmenu/plugin.min.js',
          directionality: '/tinymce/plugins/directionality/plugin.min.js',
          emoticons: '/tinymce/plugins/emoticons/plugin.min.js',
          fullscreen: '/tinymce/plugins/fullscreen/plugin.min.js',
          hr: '/tinymce/plugins/hr/plugin.min.js',
          image: '/tinymce/plugins/image/plugin.min.js',
          imagetools: '/tinymce/plugins/imagetools/plugin.min.js',
          insertdatetime: '/tinymce/plugins/insertdatetime/plugin.min.js',
          link: '/tinymce/plugins/link/plugin.min.js',
          lists: '/tinymce/plugins/lists/plugin.min.js',
          media: '/tinymce/plugins/media/plugin.min.js',
          nonbreaking: '/tinymce/plugins/nonbreaking/plugin.min.js',
          pagebreak: '/tinymce/plugins/pagebreak/plugin.min.js',
          paste: '/tinymce/plugins/paste/plugin.min.js',
          preview: '/tinymce/plugins/preview/plugin.min.js',
          save: '/tinymce/plugins/save/plugin.min.js',
          searchreplace: '/tinymce/plugins/searchreplace/plugin.min.js',
          spellchecker: '/tinymce/plugins/spellchecker/plugin.min.js',
          table: '/tinymce/plugins/table/plugin.min.js',
          template: '/tinymce/plugins/template/plugin.min.js',
          textcolor: '/tinymce/plugins/textcolor/plugin.min.js',
          visualblocks: '/tinymce/plugins/visualblocks/plugin.min.js',
          visualchars: '/tinymce/plugins/visualchars/plugin.min.js',
          //wordcount: '/tinymce/plugins/wordcount/plugin.min.js'
        },

        content_css: '/tinymce/skins/content/default/content.min.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        theme_url: '/tinymce/themes/silver/theme.min.js',

        menubar: 'edit views insert format  table',

        toolbar: 'undo redo | styleselect | bold italic underline | forecolor textcolor backcolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | fullscreen | link image media | code codesample',
      },
    }
  },

  validations: {
    title: {
      minLength: minLength(1),
      maxLength: maxLength(200),
      required,
    },
    content: {
      required,
    }
  },

  mounted() {
    this.title = "";
    this.content = "";
    this.for_bank_employees = false;
    if (this.showEditor && this.editId) return this.getEditFAQ(this.editId);

    if (!this.showEditor) return this.$emit('close');
  },

  watch: {
    showEditor(val) {
      if (!val) return this.$emit('close');
    }
  },

  methods: {
    validationForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.editId) return this.updateNotifications();
        this.saveFAQ();
      }
    },

    saveFAQ() {
      let self = this;
      axios.post(`/api/faq`, {
        name: self.title,
        message: self.content,
        is_privatbank: self.for_bank_employees
      }).then(function (response) {
        self.showID = response.data.name;
        this.$emit('updateFAQ', self.showID);
        this.$emit('close')
        // self.showEditor = false;
      });
    },

    updateNotifications() {
      let self = this;
      axios.put(`/api/faq/${self.editId}`, {
        name: self.title,
        message: self.content,
        is_privatbank: self.for_bank_employees
      }).then(function (response) {
        // self.getAnswers();
        self.showID = response.data.id;
        self.$emit('updateFAQ', self.showID)
        // self.showEditor = false;
        this.$emit('close')
        self.editId = null;
      })
    },

    getEditFAQ(id) {
      let self = this;
      axios.get(`/api/faq/${+id}`).then(function (response) {
        self.title = response.data.name;
        self.content = response.data.message;
        self.for_bank_employees = response.data.is_privatbank;
        //self.editId = response.data.id;
      });
    },

    cancelEditor() {
      // this.showEditor = false;
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
